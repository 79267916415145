:root {
    /* --clr-btn: hsl(317 100% 54%); */
    --clr-btn: #FFD700;
    /* --clr-btn: #ff901f; */
    /* --clr-btn: #ff2975; */
    /* --clr-btn: #f222ff; */
    /* --clr-btn: #8c1eff; */
    /* --clr-btn: white; */
    /* --clr-btn: #5e7af7; */
    --clr-bg: hsl(323 21% 16%);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.glowing-button {
    font-size: 1.25rem;

    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: var(--clr-btn);
    border: var(--clr-btn) 0.125em solid;
    padding: 0.25em 1em;
    border-radius: 0.25em;

    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;

    box-shadow: inset 0 0 0.5em 0 var(--clr-btn), 0 0 0.5em 0 var(--clr-btn);

    position: relative;
    z-index: 1;
}

.glowing-button::before {
    pointer-events: none;
    content: "";
    position: absolute;
    background: var(--clr-btn);
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;

    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0.7;
}

.glowing-button::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em var(--clr-btn);
    opacity: 0;
    background-color: var(--clr-btn);
    transition: opacity 100ms linear;
    z-index: -1;
}

.glowing-button:hover,
.glowing-button:focus {
    color: var(--clr-bg);
    text-shadow: none;
}

.glowing-button:hover::before,
.glowing-button:focus::before {
    opacity: 1;
}
.glowing-button:hover::after,
.glowing-button:focus::after {
    opacity: 1;
}
  