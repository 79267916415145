.my-skills
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
}

.my-skills > *
{
    margin: auto;
}

.project-container
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    justify-items: center;
    margin-bottom: 1rem;
}

.project-links-container
{
    display: flex;
    flex-wrap: wrap;
}

.project-filter
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.filter-button
{
    color: red;
    border: 2px solid red;
}

@media all and (max-width: 935px)
{
    .project-container, .my-skills
    {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

@media all and (max-width: 330px)
{
    .project-container, .my-skills
    {
        grid-template-columns: 1fr;
    }
}