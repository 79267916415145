.test {
    display: grid;
}

.personal-info
{
    text-align: center;
    font-family: sans-serif;
    font-size: 12px;
}

.personal-info p
{
    margin: 0;
}

.name
{
    font-size: 40px;
}

#first-name
{
    font-weight: 100;
    color: #4A4A4A
}

#last-name
{
    font-weight: bold;
}

#location
{
    font-style: italic;
    color: grey;
}

.personal-link
{
    text-decoration: none;
}

.section
{
    /* margin-bottom: 10px; */
    font-family: sans-serif;
}

.section-title {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.section-title::after {
    content: '';
    flex: 1;
    height: 2px;
    background-color: grey;
}

.section-instance
{
    margin-bottom: 15px;
}

.row-one, .row-two
{
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}

.section-company
{
    font-weight: bold;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    flex: 1;
}

#instance-title
{
    font-weight: normal;
}

.instance-location, .instance-duration
{
    margin: 0;
    font-size: 14px;
    font-style: italic;
}

.section-stack
{
    margin: 0;
    flex: 1;
    font-style: italic;
    font-size: 14px;
}

.section-description
{
    margin: 0;
    margin-top: 5px;
    padding: 0;
    font-size: 13px;
}

.section-info
{
    font-weight: normal;
    font-weight: 10px;
    margin: 0;
    padding: 0;
    list-style-position: inside;
}

.project-links
{
    display: flex;
    font-size: 14px;
    gap: 5px;
}

.project-link
{
    text-decoration: none;
    color: #3f51b5;
    font-weight: bold;
    display: flex;
    gap: 3px;
    align-content: center;
}

.link-name
{
    margin: 0;
}

.skill-instance
{
    display: flex;
    padding: 0;
    margin: 0;
    align-content: center;
}

.skill-type
{
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
}

.skill-list
{
    margin-top: 5px;
    font-size: 14px;
}

.project-grid
{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
}

.project-grid > *
{
    flex-grow: 30%;
    flex-basis: 30%;
}

.project-container
{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    align-content: flex-start;
}

.project-name
{
    font-weight: bold;
    text-align: center;
    font-size: 1.1rem;
}

.project-picture-container
{
    justify-content: center;
    display: flex;
}

.project-picture
{
    max-width: 250px;
    max-height: 120px;
    border-radius: 15px;
}